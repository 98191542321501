import axios from '../utils/request'
import md5 from 'js-md5'

function getNowTime () {
  var date = new Date()
  // 年
  var year = date.getFullYear()
  // 月
  var month = date.getMonth() + 1
  // 日
  var day = date.getDate()

  var rq = year.toString() + (month > 9 ? month.toString() : '0' + month.toString()) + (day > 9 ? day.toString() : '0' + day.toString())

  return rq
}

// post 校验标签
export const checkDevice = (data, header) => {
  return axios({
    url: '/label/checkDevice',
    method: 'post',
    data,
    config: {
      requestBase: 'VUE_APP_BASE_URL',
      headers: header,
      timeout: 10000
    }
  })
}

// post
export const getShopInfo = (data) => {
  return axios({
    url: '/trance/getCommodityInfo',
    method: 'post',
    data,
    config: {
      requestBase: 'VUE_APP_URL_PLATFORM',
      headers: {
        token: md5('kaiconn' + getNowTime())
      },
      timeout: 10000
    }
  })
}

export const genUrlScheme = (data) => {
  return axios({
    url: '/trance/genUrlScheme',
    method: 'post',
    data,
    config: {
      requestBase: 'VUE_APP_URL_PLATFORM',
      headers: {
        token: md5('kaiconn' + getNowTime())
      },
      timeout: 10000
    }
  })
}

// export const getSampleType = (data) => {
//   return axios({
//     url: "/wx/getSampleType",
//     method: "post",
//     data,
//     config: {
//       headers: {
//         'token': md5('MeiKai'+getNowTime())
//       },
//       timeout: 10000
//     }
//   })
// }
