<template>
  <div class="index" ref="container">
    <a-alert
      message="Error"
      description="请通过碰一碰标签打开此链接!"
      type="error"
      show-icon
      v-if="show"
    >
      <template #icon>
        <smile-outlined/>
      </template>
    </a-alert>
    <!--    <a-modal-->
    <!--      v-model:visible="modalVisible"-->
    <!--      centered-->
    <!--      @ok="gotoKaiconn"-->
    <!--      @cancel="closeModal"-->
    <!--    >-->
    <!--      是否打开每开APP？-->
    <!--    </a-modal>-->
    <div style="width: 100%;height: 60px;display: flex;flex-direction: row;" v-if="modalVisible === true">
      <div style="width: 70%;height: 100%;display: flex;flex-direction: row;background-color: black;">
        <div
          style="width: 15%;height: 100%;display: flex;justify-content: center;align-items: center;color: white;min-width: 40px;">
          <close-circle-outlined :style="{ fontSize: '16px' }" @click="closeModal"/>
        </div>
        <div
          style="width: 20%;height: 100%;display: flex;justify-content: center;align-items: center;color: white;min-width: 52px;">
          <img src="../assets/logo36.png" style="width: 36px;height: 36px;" fit="contain"/>
        </div>
        <div style="width: 65%;height: 100%;display: flex;flex-direction: column;">
          <div
            style="width: 100%;height: 50%;display: flex;justify-content: flex-start;align-items: flex-end;color: white;">
            打开每开APP
          </div>
          <div
            style="width: 100%;height: 50%;display: flex;justify-content: flex-start;align-items: flex-start;color: #cccbcb;">
            让世界联接更美好
          </div>
        </div>
      </div>
      <div
        style="width: 30%;height: 100%;display: flex;justify-content: center;align-items: center;background-color: #0082FE;font-size: 16px;color: white;"
        @click="gotoKaiconn">
        立即打开
      </div>
    </div>
    <div class="main">
      <a-carousel class="carousel" :autoplay="true" dotPosition="bottom">
        <div v-for="(item, index) in bannerImg" :key="index">
          <div class="certImages" v-viewer><img style="width: 100%; height: 100%;" :src="item" ></div>
        </div>
      </a-carousel>
    </div>
    <div class="price" id="commodity">
      <div class="price1">
        <span class="title">{{ result?.name }}</span>
        <span class="title1">品牌</span>
        <div class="price3">
          <span class="title2">{{ area?.communityName }}</span>
        </div>
        <div class="price2" v-if="result?.specifications !== ''">
          <span class="title3">型号</span>
          <span class="title4">
            <a-tag v-for="tag in result?.specifications.split(',')" :key="tag" :closable="false">
            {{ tag }}
            </a-tag>
          </span>
        </div>
      </div>
    </div>
    <div class="commodity-info">
      <div class="commodity-information">商品参数</div>
    </div>
    <div class="commodity-type" v-if="result?.description !== ''">
      <div class="blank1">
        描述
      </div>
      <div class="blank2">
        {{ result?.description }}
      </div>
    </div>
    <!--    <div class="commodity-type" v-if="result?.growSource.length !== 0">-->
    <!--      <div class="blank">-->
    <!--        <div class="blank1">-->
    <!--          <div class="blank3">编号</div>-->
    <!--        </div>-->
    <!--        <div class="blank2">-->
    <!--          <div class="blank4">{{ result?.growSource[0].batchNumber }}</div>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->
    <!--    <div class="commodity-type" v-if="result?.shopInfo.shelfLife !== 0">-->
    <!--      <div class="blank">-->
    <!--        <div class="blank1">-->
    <!--          <div class="blank3">保质期</div>-->
    <!--        </div>-->
    <!--        <div class="blank2">-->
    <!--          <div class="blank4">{{ result?.shopInfo.shelfLife }}个月</div>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->
    <!--    <div class="commodity-type" v-if="result?.growSource.length !== 0">-->
    <!--      <div class="blank">-->
    <!--        <div class="blank1">-->
    <!--          <div class="blank3">生产日期</div>-->
    <!--        </div>-->
    <!--        <div class="blank2">-->
    <!--          <div class="blank4">{{ result?.growSource[0].batchNumber }}</div>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->
    <div class="commodity-type" v-if="area?.deptName !== ''">
      <div class="blank1">
        生产企业
      </div>
      <div class="blank2">
        {{ area?.deptName }}
      </div>
    </div>
    <!--    <div class="commodity-type">-->
    <!--      <div class="blank">-->
    <!--        <div class="blank1">-->
    <!--          <div class="blank3">生产工艺</div>-->
    <!--        </div>-->
    <!--        <div class="blank2">-->
    <!--          <div class="blank4">种植、采果、加工、包装、运输</div>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->
    <!--    <div class="commodity-type">-->
    <!--      <div class="blank">-->
    <!--        <div class="blank1">-->
    <!--          <div class="blank3">食用方法</div>-->
    <!--        </div>-->
    <!--        <div class="blank2">-->
    <!--          <div class="blank4">去皮后食用</div>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->
    <div class="commodity-type">
      <div class="blank1">
        规格
      </div>
      <div class="blank2">
        净重{{ result?.weight }}{{ result?.unit }}
      </div>
    </div>
    <div v-if="descUrls.length !== 0" class="commodity-type" v-for="(item, index) in descUrls" v-bind:key="index">
      <img v-viewer :src="item" class="commodity-descImg"/>
    </div>
    <div style="height: 10px; margin-bottom: 1px;"></div>
    <!--    <div class="commodity-type">-->
    <!--      <a-affix :target="() => this.$refs.container" style="width:100%;">-->
    <!--        <div class="anchor-self">-->
    <!--          <a-anchor>-->
    <!--            <a-anchor-link href="#commodity" title="商品信息"/>-->
    <!--            <a-anchor-link href="#brandStory" title="品牌故事"/>-->
    <!--&lt;!&ndash;            <a-anchor-link href="#traceabilityInformation" title="追溯信息"&ndash;&gt;-->
    <!--&lt;!&ndash;                           v-if="result?.certificateSource.length > 0 && result?.growSource.length > 0"/>&ndash;&gt;-->
    <!--          </a-anchor>-->
    <!--        </div>-->
    <!--      </a-affix>-->
    <!--    </div>-->
    <!--    <div class="brand" id="brandStory">-->
    <!--      <div class="price1">-->
    <!--        <span class="brandTitle">品牌故事</span>-->
    <!--        <span class="brandTitle1" v-if="area?.iconUrl">-->
    <!--          <img :src="area?.iconUrl" class="brandLogo" fit="contain">-->
    <!--        </span>-->
    <!--        <div class="price3">-->
    <!--          <span class="brandTitle2">{{ result?.name }}</span>-->
    <!--        </div>-->
    <!--        <div class="price3" v-if="area?.introduction">-->
    <!--          <div class="brandTitle3">{{ area?.introduction }}</div>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->
    <!--    <div class="brand" id="traceabilityInformation"-->
    <!--         v-if="result?.certificateSource.length > 0 && result?.growSource.length > 0">-->
    <!--      <div class="price1">-->
    <!--        <span class="brandTitle">追溯信息</span>-->
    <!--        <div class="collapse-self">-->
    <!--          <a-collapse :bordered="false" accordion>-->
    <!--            <a-collapse-panel header="生长追溯" :style="customStyle" v-if="result?.growSource.length > 0">-->
    <!--              <a-timeline v-for="(item, index) in result?.growSource" :key="index">-->
    <!--                <a-timeline-item color="gray" style="margin-top: 15px;">-->
    <!--                  <template #dot v-if="index === 0">-->
    <!--                    <Icon>-->
    <!--                      <template #component>-->
    <!--                        <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1"-->
    <!--                             xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">-->
    <!--                          <title>编组 4</title>-->
    <!--                          <g id="页面-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">-->
    <!--                            <g id="切图" transform="translate(-298.000000, -86.000000)">-->
    <!--                              <g id="编组-4" transform="translate(302.000000, 90.000000)">-->
    <!--                                <circle id="椭圆形" stroke-opacity="0.129998907" stroke="#00CACE" stroke-width="4"-->
    <!--                                        fill="#00CACE" cx="8" cy="8" r="10"></circle>-->
    <!--                                <path-->
    <!--                                  d="M9.57741102,6.57741102 C9.90284793,6.25197411 10.4304854,6.25197411 10.7559223,6.57741102 C11.0542395,6.87572818 11.0790992,7.34395 10.8305016,7.67058461 L10.7559223,7.75592232 L7.75592232,10.7559223 C7.45760515,11.0542395 6.98938334,11.0790992 6.66274873,10.8305016 L6.57741102,10.7559223 L5.07741102,9.25592232 C4.75197411,8.93048541 4.75197411,8.40284793 5.07741102,8.07741102 C5.37572818,7.77909385 5.84395,7.75423408 6.17058461,8.00283172 L6.25592232,8.07741102 L7.166,8.988 L9.57741102,6.57741102 Z"-->
    <!--                                  id="路径" fill="#FFFFFF" fill-rule="nonzero"></path>-->
    <!--                              </g>-->
    <!--                            </g>-->
    <!--                          </g>-->
    <!--                        </svg>-->
    <!--                      </template>-->
    <!--                    </Icon>-->
    <!--                  </template>-->
    <!--                  <span class="timeLineTitle">{{ item.message }}</span>-->
    <!--                  <br>-->
    <!--                  <span class="timeLineTime">{{ parseTime(item.sourceTime) }}</span>-->
    <!--                </a-timeline-item>-->
    <!--              </a-timeline>-->
    <!--            </a-collapse-panel>-->
    <!--            &lt;!&ndash;            <a-collapse-panel header="周转追溯" :style="customStyle">&ndash;&gt;-->
    <!--            &lt;!&ndash;              <a-timeline>&ndash;&gt;-->
    <!--            &lt;!&ndash;                <a-timeline-item style="margin-top: 15px;">&ndash;&gt;-->
    <!--            &lt;!&ndash;                  <template #dot>&ndash;&gt;-->
    <!--            &lt;!&ndash;                    <Icon>&ndash;&gt;-->
    <!--            &lt;!&ndash;                      <template #component>&ndash;&gt;-->
    <!--            &lt;!&ndash;                        <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">&ndash;&gt;-->
    <!--            &lt;!&ndash;                          <title>编组 4</title>&ndash;&gt;-->
    <!--            &lt;!&ndash;                          <g id="页面-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">&ndash;&gt;-->
    <!--            &lt;!&ndash;                            <g id="切图" transform="translate(-298.000000, -86.000000)">&ndash;&gt;-->
    <!--            &lt;!&ndash;                              <g id="编组-4" transform="translate(302.000000, 90.000000)">&ndash;&gt;-->
    <!--            &lt;!&ndash;                                <circle id="椭圆形" stroke-opacity="0.129998907" stroke="#00CACE" stroke-width="4" fill="#00CACE" cx="8" cy="8" r="10"></circle>&ndash;&gt;-->
    <!--            &lt;!&ndash;                                <path d="M9.57741102,6.57741102 C9.90284793,6.25197411 10.4304854,6.25197411 10.7559223,6.57741102 C11.0542395,6.87572818 11.0790992,7.34395 10.8305016,7.67058461 L10.7559223,7.75592232 L7.75592232,10.7559223 C7.45760515,11.0542395 6.98938334,11.0790992 6.66274873,10.8305016 L6.57741102,10.7559223 L5.07741102,9.25592232 C4.75197411,8.93048541 4.75197411,8.40284793 5.07741102,8.07741102 C5.37572818,7.77909385 5.84395,7.75423408 6.17058461,8.00283172 L6.25592232,8.07741102 L7.166,8.988 L9.57741102,6.57741102 Z" id="路径" fill="#FFFFFF" fill-rule="nonzero"></path>&ndash;&gt;-->
    <!--            &lt;!&ndash;                              </g>&ndash;&gt;-->
    <!--            &lt;!&ndash;                            </g>&ndash;&gt;-->
    <!--            &lt;!&ndash;                          </g>&ndash;&gt;-->
    <!--            &lt;!&ndash;                        </svg>&ndash;&gt;-->
    <!--            &lt;!&ndash;                      </template>&ndash;&gt;-->
    <!--            &lt;!&ndash;                    </Icon>&ndash;&gt;-->
    <!--            &lt;!&ndash;                  </template>&ndash;&gt;-->
    <!--            &lt;!&ndash;                  <span class="timeLineTitle">商品已售出，期待再次为您服务</span>&ndash;&gt;-->
    <!--            &lt;!&ndash;                  <br>&ndash;&gt;-->
    <!--            &lt;!&ndash;                  <span class="timeLineTime">2021-04-01 12:00:00</span>&ndash;&gt;-->
    <!--            &lt;!&ndash;                </a-timeline-item>&ndash;&gt;-->
    <!--            &lt;!&ndash;                <a-timeline-item color="gray">&ndash;&gt;-->
    <!--            &lt;!&ndash;                  <span class="timeLineTitle">到达目的地网点销售中心</span>&ndash;&gt;-->
    <!--            &lt;!&ndash;                  <br>&ndash;&gt;-->
    <!--            &lt;!&ndash;                  <span class="timeLineTime">2021-04-01 12:00:00</span>&ndash;&gt;-->
    <!--            &lt;!&ndash;                </a-timeline-item>&ndash;&gt;-->
    <!--            &lt;!&ndash;                <a-timeline-item color="gray">&ndash;&gt;-->
    <!--            &lt;!&ndash;                  <span class="timeLineTitle">在广东深圳公司进行发出扫描</span>&ndash;&gt;-->
    <!--            &lt;!&ndash;                  <br>&ndash;&gt;-->
    <!--            &lt;!&ndash;                  <span class="timeLineTime">2021-04-01 12:00:00</span>&ndash;&gt;-->
    <!--            &lt;!&ndash;                </a-timeline-item>&ndash;&gt;-->
    <!--            &lt;!&ndash;                <a-timeline-item color="gray">&ndash;&gt;-->
    <!--            &lt;!&ndash;                  <span class="timeLineTitle">在分拨中心广东深圳公司进行卸车扫描</span>&ndash;&gt;-->
    <!--            &lt;!&ndash;                  <br>&ndash;&gt;-->
    <!--            &lt;!&ndash;                  <span class="timeLineTime">2021-04-01 12:00:00</span>&ndash;&gt;-->
    <!--            &lt;!&ndash;                </a-timeline-item>&ndash;&gt;-->
    <!--            &lt;!&ndash;                <a-timeline-item color="gray">&ndash;&gt;-->
    <!--            &lt;!&ndash;                  <span class="timeLineTitle">在金边港口进行发出扫描</span>&ndash;&gt;-->
    <!--            &lt;!&ndash;                  <br>&ndash;&gt;-->
    <!--            &lt;!&ndash;                  <span class="timeLineTime">2021-04-01 12:00:00</span>&ndash;&gt;-->
    <!--            &lt;!&ndash;                </a-timeline-item>&ndash;&gt;-->
    <!--            &lt;!&ndash;                <a-timeline-item color="gray">&ndash;&gt;-->
    <!--            &lt;!&ndash;                  <span class="timeLineTitle">在金边市进行分装扫码</span>&ndash;&gt;-->
    <!--            &lt;!&ndash;                  <br>&ndash;&gt;-->
    <!--            &lt;!&ndash;                  <span class="timeLineTime">2021-04-01 12:00:00</span>&ndash;&gt;-->
    <!--            &lt;!&ndash;                </a-timeline-item>&ndash;&gt;-->
    <!--            &lt;!&ndash;                <a-timeline-item color="gray">&ndash;&gt;-->
    <!--            &lt;!&ndash;                  <span class="timeLineTitle">在奥西瓦尔克加工厂进行采果加工</span>&ndash;&gt;-->
    <!--            &lt;!&ndash;                  <br>&ndash;&gt;-->
    <!--            &lt;!&ndash;                  <span class="timeLineTime">2021-04-01 12:00:00</span>&ndash;&gt;-->
    <!--            &lt;!&ndash;                </a-timeline-item>&ndash;&gt;-->
    <!--            &lt;!&ndash;              </a-timeline>&ndash;&gt;-->
    <!--            &lt;!&ndash;            </a-collapse-panel>&ndash;&gt;-->
    <!--            <a-collapse-panel header="检验追溯" :style="customStyle" v-if="result?.certificateSource.length > 0">-->
    <!--              <a-timeline v-for="(item, index) in result?.certificateSource" :key="index">-->
    <!--                <a-timeline-item style="margin-top: 15px;">-->
    <!--                  <template #dot>-->
    <!--                    <Icon>-->
    <!--                      <template #component>-->
    <!--                        <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1"-->
    <!--                             xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">-->
    <!--                          <title>编组 4</title>-->
    <!--                          <g id="页面-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">-->
    <!--                            <g id="切图" transform="translate(-298.000000, -86.000000)">-->
    <!--                              <g id="编组-4" transform="translate(302.000000, 90.000000)">-->
    <!--                                <circle id="椭圆形" stroke-opacity="0.129998907" stroke="#00CACE" stroke-width="4"-->
    <!--                                        fill="#00CACE" cx="8" cy="8" r="10"></circle>-->
    <!--                                <path-->
    <!--                                  d="M9.57741102,6.57741102 C9.90284793,6.25197411 10.4304854,6.25197411 10.7559223,6.57741102 C11.0542395,6.87572818 11.0790992,7.34395 10.8305016,7.67058461 L10.7559223,7.75592232 L7.75592232,10.7559223 C7.45760515,11.0542395 6.98938334,11.0790992 6.66274873,10.8305016 L6.57741102,10.7559223 L5.07741102,9.25592232 C4.75197411,8.93048541 4.75197411,8.40284793 5.07741102,8.07741102 C5.37572818,7.77909385 5.84395,7.75423408 6.17058461,8.00283172 L6.25592232,8.07741102 L7.166,8.988 L9.57741102,6.57741102 Z"-->
    <!--                                  id="路径" fill="#FFFFFF" fill-rule="nonzero"></path>-->
    <!--                              </g>-->
    <!--                            </g>-->
    <!--                          </g>-->
    <!--                        </svg>-->
    <!--                      </template>-->
    <!--                    </Icon>-->
    <!--                  </template>-->
    <!--                  <span class="timeLineTitle">生产检测</span>-->
    <!--                  <br>-->
    <!--                  &lt;!&ndash;                  <span class="timeLineTime">2021-04-01 12:00:00</span>&ndash;&gt;-->
    <!--                  &lt;!&ndash;                  <br>&ndash;&gt;-->
    <!--                  <span class="timeLineTime">{{ item.message }}</span>-->
    <!--                  <br>-->
    <!--                  &lt;!&ndash;                  <span class="timeLineTime">报告号：00941519</span>&ndash;&gt;-->
    <!--                  <div class="certImages" v-viewer><img style="width: 100%" :src="item.cardUrl" fit="contain"></div>-->
    <!--                </a-timeline-item>-->
    <!--              </a-timeline>-->
    <!--            </a-collapse-panel>-->
    <!--          </a-collapse>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->
  </div>
  <div class="buy-footer" @click="goWx()">
    <div :class="result?.status === 0 ? 'buy' : 'buy-disabled'">
      <img src="../assets/buy.webp" class="buyLogo" fit="contain"/>
      <div class="buy-center"></div>
      {{ result?.status === 0 ? '立即购买' : '暂未发售' }}
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { defineComponent } from 'vue'
import { SmileOutlined, CloseCircleOutlined } from '@ant-design/icons-vue'
import { getShopInfo, genUrlScheme } from '@/api/api'
import storage from '../models/storage'
import { message } from 'ant-design-vue'

export default defineComponent({
  name: 'Home',
  components: {
    SmileOutlined,
    CloseCircleOutlined
  },
  data () {
    return {
      href: undefined,
      code: undefined,
      result: undefined,
      area: undefined,
      bannerImg: [],
      descUrls: [],
      certPhoto: 'http://image.nokeiot.com/Fhf9zgpv4bDltADKLn7btBM9KvC0',
      show: false,
      scheme: undefined,
      modalVisible: false
    }
  },
  watch: {
    $route: {
      handler: function (route) {
        var that = this
        if (route.query.code) {
          that.code = route.query.code
          that.handleCrypto()
        } else {
          that.show = true
        }
      },
      immediate: true,
      deep: true
    }
  },
  created () {
    message.config({
      top: '60px',
      duration: 2,
      maxCount: 3
    })
    // this.modalVisible = true
  },
  methods: {
    handleClick (e, link) {
      e.preventDefault()
      this.href0 = link.href
    },
    getCurrentAnchor () {
      return this.href
    },
    handleCrypto () {
      var that = this
      // var ts = Date.parse(new Date())
      // var strToBase64 = Buffer.from(this.code + '&' + ts).toString('base64')
      // checkDevice({ code: strToBase64 }).then(res => {
      //   if (res.status === 200) {
      // console.log(res)
      getShopInfo({ id: that.code }).then(getShopInfoRes => {
        // console.log(getShopInfoRes.result.shopInfo.bannerUrl.split(','))
        console.log(getShopInfoRes)
        that.result = getShopInfoRes.data
        that.area = getShopInfoRes.area
        // that.result.certificateSource.message = getShopInfoRes.result.certificateSource[0].message.split('\\r\\n')
        that.bannerImg = getShopInfoRes.data.bannerUrl.split(',')
        that.descUrls = getShopInfoRes.data.descUrl.split(',')
        that.scheme = getShopInfoRes.data.scheme
      })
      //   } else {
      //     console.log(res)
      //   }
      // })
      // base64反解析为字符串
      // var base64ToStr = new Buffer(To , 'base64').toString();
    },
    closeModal () {
      this.modalVisible = false
    },
    gotoKaiconn () {
      if (navigator.userAgent.match(/(iPhone|iPod|iPad);?/i)) {
        var loadDateTime = new Date()
        window.location = 'meikai://' // schema链接或者universal link
        window.setTimeout(function () { // 如果没有安装app,便会执行setTimeout跳转下载页
          var timeOutDateTime = new Date()
          if (timeOutDateTime - loadDateTime < 5000) {
            window.location = 'https://apps.apple.com/cn/app/每开/id1488108739' // ios下载地址
          } else {
            window.close()
          }
        }, 500)
      } else if (navigator.userAgent.match(/android/i)) {
        try {
          window.location = 'meikai://welcome' // schema链接或者universal link
          window.setTimeout(function () {
            window.location = 'https://aniot.nokeiot.com/apk/meikai.apk' // android下载地址
          }, 500)
        } catch (e) {
        }
      }
    },
    // 日期格式化
    parseTime (time, pattern) {
      if (arguments.length === 0 || !time) {
        return null
      }
      const format = pattern || '{y}-{m}-{d} {h}:{i}:{s}'
      let date
      if (typeof time === 'object') {
        date = time
      } else {
        if ((typeof time === 'string') && (/^[0-9]+$/.test(time))) {
          time = parseInt(time)
        } else if (typeof time === 'string') {
          time = time.replace(new RegExp(/-/gm), '/')
        }
        if ((typeof time === 'number') && (time.toString().length === 10)) {
          time = time * 1000
        }
        date = new Date(time)
      }
      const formatObj = {
        y: date.getFullYear(),
        m: date.getMonth() + 1,
        d: date.getDate(),
        h: date.getHours(),
        i: date.getMinutes(),
        s: date.getSeconds(),
        a: date.getDay()
      }
      const timeStr = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
        let value = formatObj[key]
        // Note: getDay() returns 0 on Sunday
        if (key === 'a') {
          return ['日', '一', '二', '三', '四', '五', '六'][value]
        }
        if (result.length > 0 && value < 10) {
          value = '0' + value
        }
        return value || 0
      })
      return timeStr
    },
    goWx () {
      var that = this
      if (that.result.status !== 0) {
        message.error('暂未发售!')
      } else {
        var scheme = storage.get('kaiconn-traceScheme-' + String(this.result.id))
        if (scheme) {
          try {
            window.location.href = scheme
          } catch (e) {
            console.error(e)
          }
        } else {
          genUrlScheme({ id: this.result.id }).then(async res => {
            if (res.code === 200) {
              try {
                await storage.set('kaiconn-traceScheme-' + String(this.result.id), res.result, 2592000000)
                window.location.href = res.result
              } catch (e) {
                console.error(e)
              }
            } else {
              console.error(res.msg)
            }
          })
        }
      }
      // storage.set("kaiconn-traceScheme-" + String(this.result.shopInfo.id), 'weixin://dl/business/?t=1AV5EATaM9u', 2592000000)
    }
  },
  setup () {
    const customStyle = 'margin-bottom: 24px;'
    return {
      customStyle
    }
  }

})
</script>

<style>
.index {
  position: relative;
  background-color: #F6F7FB;
  height: calc(100% - 64px);
  width: 100%;
  overflow: auto;
}

.main {
  width: 100%;
  height: 384px;
  background-color: #F6F7FB;
}

.price {
  width: 100%;
  min-height: 170px;
  margin-top: 12px;
  background-color: #F6F7FB;
  display: flex;
  justify-content: center;
  align-items: center;
}

.price1 {
  display: flex;
  flex-direction: column; /*默认也是row可以不写*/
  width: 93.6%;
  height: 100%;
  background: #FFFFFF;
  border-radius: 4px;
  justify-content: left;
  align-items: center;
}

.title {
  margin-top: 16px;
  width: 93.16%;
  min-height: 28px;
  font-size: 20px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #000000;
  line-height: 28px;
  text-align: left;
}

.title1 {
  width: 93.16%;
  height: 14px;
  font-size: 10px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  line-height: 14px;
  text-align: left;
  margin-top: 16px;
}

.title2 {
  width: 71.23%;
  height: 44px;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 22px;
  text-align: left;
}

.price3 {
  margin-top: 8px;
  width: 93.16%;
  min-height: 44px;
  display: flex;
  justify-content: left;
  align-items: center;
}

.price2 {
  margin-top: 16px;
  margin-bottom: 16px;
  width: 93.16%;
  min-height: 28px;
  background: #F6F7FB;
  border-radius: 4px;
  display: flex;
  flex-direction: row; /*默认也是row可以不写*/
  justify-content: left;
  align-items: stretch; /* 这将确保子元素填满父元素的高度 */
}

.title3 {
  margin-left: 9px;
  min-width: 45px;
  font-size: 12px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #86858F;
  line-height: 17px;
  display: flex;
  flex-direction: row; /*默认也是row可以不写*/
  justify-content: center;
  align-items: center;
}

.title4 {
  margin-left: 9px;
  min-width: 45px;
  font-size: 12px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #00CACE;
  line-height: 17px;
}

.buy-footer {
  height: 64px;
  background: #FFFFFF;
  box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.1);
  position: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
  flex-direction: row; /*默认也是row可以不写*/
  justify-content: center;
  align-items: center;
}

.buy {
  width: 93.6%;
  height: 44px;
  background: #00CACE;
  display: flex;
  flex-direction: row; /*默认也是row可以不写*/
  justify-content: center;
  align-items: center;
  font-size: 15px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 21px;
}

.buy-disabled {
  width: 93.6%;
  height: 44px;
  background: #999999 !important;
  display: flex;
  flex-direction: row; /*默认也是row可以不写*/
  justify-content: center;
  align-items: center;
  font-size: 15px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #FFFFFF !important;
  line-height: 21px;
}

.buy-center {
  width: 3.2%;
}

.buyLogo {
  width: 20px;
  height: 20px;
  background: rgba(255, 255, 255, 0.01);
}

.commodity-info {
  width: 100%;
  height: 2.59%;
  display: flex;
  flex-direction: row; /*默认也是row可以不写*/
  justify-content: center;
  align-items: center;
  margin-top: 24px;
}

.commodity-information {
  width: 93.6%;
  height: 100%;
  font-size: 15px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #000000;
  line-height: 21px;
  display: flex;
  flex-direction: row; /*默认也是row可以不写*/
  justify-content: left;
  align-items: center;
}

.commodity-type {
  margin-left: 3.2%;
  width: 93.6%;
  min-height: 4.93%; /* 或设置一个固定的像素值，如 min-height: 100px; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch; /* 这将确保子元素填满父元素的高度 */
  margin-top: 8px;
}

.commodity-descImg {
  width: 100%;

}

.blank1,
.blank2 {
  display: flex;
  align-items: center; /* 垂直居中对齐 */
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #000000;
  line-height: 17px;
}

.blank1 {
  width: 25.07%;
  background: #FFFFFF;
  justify-content: center; /* 水平居中对齐内容 */
}

.blank2 {
  width: 73.79%;
  background: #FFFFFF;
  justify-content: flex-start; /* 内容靠左对齐 */
  padding: 5px 5px;
}

.blank3 {
  margin-left: 32.79%;
  width: 77.27%;
  height: 100%;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #000000;
  line-height: 17px;
  text-align: left;
  display: flex;
  flex-direction: row; /*默认也是row可以不写*/
  justify-content: left;
  align-items: center;
}

.blank4 {
  margin-left: 7.72%;
  width: 92.28%;
  height: 100%;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #000000;
  line-height: 17px;
  text-align: left;
  display: flex;
  flex-direction: row; /*默认也是row可以不写*/
  justify-content: left;
  align-items: center;
}

.anchor-self {
  height: 44px;
  width: 100%;
  background: #FFFFFF;
  display: flex;
  flex-direction: row; /*默认也是row可以不写*/
  justify-content: left;
  align-items: center;
}

.brand {
  width: 100%;
  min-height: 170px;
  margin-top: 12px;
  background-color: #F6F7FB;
  display: flex;
  justify-content: center;
  align-items: center;
}

.brandTitle {
  margin-top: 16px;
  width: 93.16%;
  height: 21px;
  font-size: 15px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #000000;
  line-height: 21px;
  text-align: left;
}

.brandTitle1 {
  margin-top: 8px;
  width: 93.16%;
  height: 40px;
  display: flex;
  flex-direction: row; /*默认也是row可以不写*/
  justify-content: left;
  align-items: center;
}

.brandLogo {
  width: 87px;
  height: 40px;
}

.brandTitle2 {
  width: 80.06%;
  min-height: 32px;
  font-size: 12px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #000000;
  line-height: 16px;
  text-align: left;
}

.brandTitle3 {
  width: 100%;
  min-height: 32px;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #2C2B31;
  line-height: 24px;
  text-align: left;
  background-color: #FFFFFF;
}

.collapse-self {
  width: 93.16%;
  border-radius: 4px;
  min-height: 56px;
  margin-top: 12px;
  background-color: #FFFFFF;
}

.timeLineTitle {
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #000000;
  line-height: 20px;
}

.timeLineTime {
  font-size: 12px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #86858F;
  line-height: 17px;
}

.certImages {
  width: 100%;
}
</style>

<style lang="less">
.ant-carousel {
  height: 384px;
  background: #364d79;
  overflow: hidden;

  .slick-slide {
    text-align: center;
    height: 384px;
    line-height: 384px;
    background: #364d79;
    overflow: hidden;
  }

  .slick-slide h3 {
    color: #fff;
  }
}

.ant-anchor-ink-ball.visible {
  display: none !important;
}

.ant-anchor-ink::before {
  display: none !important;
}

.ant-anchor {
  display: flex;
  flex-direction: row; /*默认也是row可以不写*/
  justify-content: left;
  align-items: center;
}

.ant-collapse {
  border-radius: 4px !important;
  background-color: #FFFFFF !important;

  .ant-collapse-item {
    .ant-collapse-header {
      background: #fafafa;
      text-align: left;
      padding-left: 16px !important;

      .ant-collapse-arrow {
        background: #fafafa !important;
        left: unset !important;
        right: 16px !important;
      }
    }
  }
}

.ant-collapse-content-box {
  background: #fafafa !important;
}

.ant-timeline-item-content {
  text-align: left;
  padding-left: 16px !important;
}

.ant-timeline-item-tail {
  border-left: 2px dotted #DBDCE0 !important;
}

.ant-timeline-item-head-custom {
  background: #fafafa !important;
}
</style>
