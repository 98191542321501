import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'lib-flexible/flexible.js'
import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css'
import 'viewerjs/dist/viewer.css'
import VueViewer from 'v-viewer'

const app = createApp(App)
app.config.productionTip = false
app.use(store)
app.use(router)
app.use(Antd)
app.use(VueViewer)
app.mount('#app')
